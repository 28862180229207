(function () {

    'use strict';

    angular.module("adminApp")
        .factory('createNewOrganizationModal', ["$uibModal", function ($uibModal) {

            function open(org) {
                var modal = $uibModal.open({
                    size: "md",
                    templateUrl: "/static/templates/admin/modals/createNewOrganizationModal.html",
                    resolve: {
                        org: function () {
                            return org;
                        }
                    },
                    controller: "CreateNewOrganizationModalCtrl"
                });
                return modal.result;
            }

            return {
                open: open
            };
        }])
        .controller("CreateNewOrganizationModalCtrl", CreateNewOrganizationModalCtrl);


    CreateNewOrganizationModalCtrl.$inject = ['$scope', '$http', '$uibModalInstance', 'aerosAdminApi', 'org', 'Notification', 'CountriesListService'];

    function CreateNewOrganizationModalCtrl($scope, $http, $uibModalInstance, aerosAdminApi, org, Notification, CountriesListService) {

        angular.extend($scope, {
            autoSelectFeaturesForVendor: autoSelectFeaturesForVendor
        });

        $scope.isSubmitted = false;
        $scope.licenseTypes = [];
        $scope.countries = CountriesListService.getCountries();
        $scope.newUserModel = {
            numLicenses: 1,
            licenseType: "",
            licenseKey: "",
            poNumber: "",

            orgName: "",
            vendorId: "",
            orgFirstName: "",
            orgLastName: "",
            orgEmail: "",
            orgPhone: "",
            orgAddress: "",
            orgAddressLine2: "",
            orgCity: "",
            orgState: "",
            orgZipCode: "",
            orgCountry: "",

            useOrgInfoAsAlternate: true,

            firstName: "",
            lastName: "",
            phone: "",
            email: "",
            address: "",
            addressLine2: "",
            city: "",
            state: "",
            zipCode: "",
            country: ""
        };

        getTerms();
        getVendors();
        getFeatures();

        function getTerms() {
            aerosAdminApi.getLicenseTerms().success(function (data) {
                var terms = data.licenseTerms;
                _.each(terms, function (term) {
                    $scope.licenseTypes.push(term);
                });
            });
        }

        function getVendors() {
            aerosAdminApi.vendorApi.list().then(function (results) {
                $scope.vendors = results.data.vendors;

                angular.forEach($scope.vendors, function (value) {
                    if (value.id == 'afl') {
                        $scope.newUserModel.vendorId = value;
                    }
                });
            });
        }

        function getFeatures() {
            $scope.selectedFeatures = [];

            aerosAdminApi.featureApi.list().then(function (results) {
                $scope.availableFeatures = results.data.features;
            });
        }

        function autoSelectFeaturesForVendor(vendor) {
            if (vendor.name.toLowerCase() == 'afl') {
                angular.forEach($scope.availableFeatures, function (item) {
                    $scope.selectedFeatures[item.name] = true;
                });
            } else {
                angular.forEach($scope.availableFeatures, function (item) {
                    $scope.selectedFeatures[item.name] = false;
                });
            }
        }

        $scope.generateLicense = function () {
            $scope.newUserModel.term = $scope.newUserModel.licenseType.name;
            aerosAdminApi.generateLicense($scope.newUserModel)
                .success(function (data) {
                    $scope.newUserModel.licenseKey = data.licenses[0].id;
                    $scope.isSubmitted = true;
                })
                .error(function (err) {
                    Notification.error("License not generated. " + err);
                    $scope.isSubmitted = false;
                });
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        };

        $scope.registerNewUser = function () {

            $scope.newUserModel.vendorId = $scope.newUserModel.vendor.id;

            $http.post("/newCustomer", $scope.newUserModel)
                .success(function (rsp) {

                    aerosAdminApi.featureApi.updateOrganization(rsp.object.id,
                        prepareFeatureListToUpdateOrganization($scope.availableFeatures, $scope.selectedFeatures))
                        .then(function () {
                            $modalInstance.close();
                            Notification.success("Contact information saved");
                        });

                    $uibModalInstance.close();
                    Notification.success("User registered. " + rsp.message);
                })
                .error(function (err) {
                    Notification.error("User not registered, " + err.message);
                });
        };

        $scope.save = function () {
            $uibModalInstance.close($scope.newUserModel);
        };

        function prepareFeatureListToUpdateOrganization(availableFeatures, selectedFeatures) {
            return availableFeatures.filter(function (element) {
                return selectedFeatures[element.name];
            })
        }
    }
})();
